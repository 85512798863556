<template>
  <validation-observer #default="{ invalid }">
    <b-form>
      <b-form-group
        label-for="is_national"
        :description= "$t('accountMx')"
      >
        <b-form-checkbox id="is_nacional" v-model="is_national">{{$t('accountNational')}}</b-form-checkbox>
      </b-form-group>

      <b-form-group
        :label="$t('nameBeneficiary')"
        label-for="beneficiary_name"
      >
        <validation-provider rules="required">
          <b-form-input id="beneficiary_name" v-model="beneficiary_name"/>
        </validation-provider>
      </b-form-group>

      <b-form-group
        :label="$t('lastNameBeneficiary')"
        label-for="beneficiary_last_name"
      >
        <validation-provider rules="required">
          <b-form-input id="beneficiary_last_name" v-model="beneficiary_last_name"/>
        </validation-provider>
      </b-form-group>

      <b-form-group
        :label="$t('bank')"
        label-for="bank"
      >
        <validation-provider rules="required">
          <b-form-input id="bank" v-model="bank"/>
        </validation-provider>
      </b-form-group>

      <b-form-group
        :label="getLabelClabe()"
        label-for="clabe"
      >
        <validation-provider rules="required">
          <b-form-input id="clabe" v-model="clabe"/>
        </validation-provider>
      </b-form-group>

      <b-form-group
        :label="$t('account')"
        label-for="account"
      >
        <validation-provider rules="required">
          <b-form-input id="account" v-model="account"/>
        </validation-provider>
      </b-form-group>

      <b-form-group
        :label="$t('email')"
        label-for="email"
      >
        <validation-provider rules="required|email" name="email" #default="{ errors }">
          <b-form-input id="email" v-model="email_value"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group :label="$t('telephone')">
      <validation-provider
        name="Celular"
      >
        <input-tel-brandme 
          :number_dad="telephone"
          @number="updateTelephone"
        ></input-tel-brandme>
      </validation-provider>
    </b-form-group>

      <b-form-group
        v-if="!is_national"
        :label="$t('city')"
        label-for="city"
      >
        <validation-provider rules="required">
          <b-form-input id="city" v-model="city"/>
        </validation-provider>
      </b-form-group>

      <b-form-group
        v-if="!is_national"
        :label="$t('diecctionInternational')"
        label-for="address"
      >
        <validation-provider rules="required">
          <b-form-input id="address" v-model="address"/>
        </validation-provider>
      </b-form-group>

      <div class="mt-1 text-center">
        <b-button
          variant="outline-success"
          :disabled="invalid || !state_telephone"
          @click="updatePyamentData()"
        >
          {{$t('dashboard.save')}}
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { BForm, BFormGroup, BFormCheckbox, BFormInput, BButton } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import invitation_service from "@/services/invitation";
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import utils from '@/libs/utils'
import abilities from '@/libs/acl/abilities'
import useJwt from '@/auth/jwt/useJwt';
import InputTelBrandme from '@/views/components/inputs/InputTelBrandme.vue';

export default {
  name: 'paymentData',
  components: {
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BButton,
    ValidationObserver,
    ValidationProvider,
    InputTelBrandme,
  },
  props: {
    campaign: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      required,
      email,
      is_national: false,
      beneficiary_name: '',
      beneficiary_last_name: '',
      bank: '',
      clabe: '',
      account: '',
      email_value: '',
      telephone: '',
      country: '',
      city: '',
      address: '',
      interval_loading: null,
      state_telephone: false,
    }
  },
  created() {
  },
  methods: {
    updateTelephone(telephone_data) {
      this.telephone = telephone_data.number;
      this.state_telephone = telephone_data.state;
      this.country = telephone_data.country;
    },
    getLabelClabe() {
      return this.is_national ? 'CLABE' : 'ABBA / SWIFT / BIC'
    },
    setUser(data) {
      utils.setPermissions(data.user);
      const abilitie = abilities.getAbilitie(data.user)
      useJwt.setToken(data.access_token)
      useJwt.setRefreshToken(data.refresh_token)
      if (!data.user.main_group) this.$router.push({name: 'auth-roll'})
      utils.setuserData(data, abilitie)
      this.$ability.update(abilitie)
      this.$store.commit('appConfig/UPDATE_IS_VERIFIED', data.user.is_verified)
      window.location.reload()
    },
    updatePyamentData() {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: utils.getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: utils.getMotivationalPhrase(),
          });
        }
      }, 5000);
      const form_data = {
        is_national: this.is_national,
        beneficiary_name: this.beneficiary_name,
        beneficiary_last_name: this.beneficiary_last_name,
        bank: this.bank,
        clabe: this.clabe,
        account: this.account,
        email: this.email_value,
        telephone: this.telephone,
        country: this.country,
        city: !this.is_national ? this.city : null,
        address: !this.is_national ? this.address : null,
        username: this.$route.params.username,
        network: this.$route.params.network,
      }

      invitation_service.updatePaymentData(form_data)
        .then(response => {
          this.setUser(response)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('dashboard.saved'),
              variant: 'success',
              icon: 'CheckCircleIcon',
              text: ''
            }
          })
          this.$emit('savePaymentData')
          utils.updatePaymentData(response.user.payment_data)
          clearInterval(this.interval_loading)
          this.$vs.loading.close()
        })
    }
  },
  watch: {
  }
};
</script>

<style>
.country-selector__list {
  min-width: 300px !important;
  height: 400px !important;
  min-height: 400px;
}
.country-selector__list__item {
  display: block;
  padding: 2em 1em;
}
.dots-text {
  order: 1;
  width: 180px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
